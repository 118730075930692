require('jquery');
import 'webpack-jquery-ui/interactions';

var ready = function() {

  $('form').on('click', '.remove_fields', function(event) {
    $(this).parent().find('input[type=hidden]').val(true)
    var new_record = $(this).closest('.row.nest').find('.new_record')
    console.log(new_record)
    console.log(new_record.val())
    console.log(new_record.val() == "true")
    if(new_record && new_record.val() == "true"){
      $(this).closest('.row.nest').remove()
    } else {
      $(this).closest('.row.nest').hide()
    }
    event.preventDefault()
  })

  $('form').on('click', '.add_fields', function(event) {
    event.preventDefault();
    var time = new Date().getTime();
    var regexp = new RegExp($(this).data('id'), 'g');
    $(this).prev().append($(this).data('fields').replace(regexp, time));
  });
};

window.need_nested_form = ready;
$(document).ready(ready)
$(document).on('page:load', ready)
$(document).on('turbolinks:load', ready)
