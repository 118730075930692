require('jquery');
import 'webpack-jquery-ui/interactions';
import ApexCharts from 'packs/ApexCharts';

window.generate_norm_chart = function(){
  var data_cont = $("#norm_chart_data").data()

  if(data_cont){
    var options = {
      series: data_cont["datasets"],
      colors: ["#02246d"],
      chart: {
        type: 'radialBar',
        offsetY: -10,
        offsetX: -40,
        width: 190,
        sparkline: {
          enabled: true
        }
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#edecef",
            strokeWidth: '100%',
            margin: 15
          },
          dataLabels: {
            name: {
              show: true,
              fontSize: '12px'
            },
            value: {
              offsetY: -33,
              fontSize: '22px',
              color: "#02246d"
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: "horizontal",
          colorStops: [
            [
              {
                offset: 0,
                color: "#5db756",
                opacity: 1
              },
              {
                offset: 50,
                color: "#f7cf38",
                opacity: 1
              },
              {
                offset: 100,
                color: "#ff2222",
                opacity: 1
              }
            ]
          ]
        },
      },
      labels: ['Normy'],
    };

    if(document.querySelector("#norm_chart").innerHTML == ""){
      var chart = new ApexCharts(document.querySelector("#norm_chart"), options);
      chart.render();
    }
  }

}



window.generate_change_chart = function() {

  var data_cont = $("#change_chart_data").data()

  if (data_cont){
    var data = data_cont["datasets"]
    var average = data_cont["norm"]

    var options = {
      series: [{
        name: "Wartości",
        data: data
      },
      {
        name: "Norma",
        data: [average,average,average,average,average,average,average,average]
      }
      ],
      chart: {
        height: 200,
        type: 'area',
        zoom: {
          enabled: false
        }
      },
      colors: ["#0a72e8", "#ffaaaa"],
      stroke: {
        curve: 'smooth',
        width: [2,1],
        dashArray: [0, 8]
      },
      markers: {
        size: [5,0],
        colors: ["#0a72e8"],
        strokeWidth: 0,
        showNullDataPoints: false,
        hover: {
          size: 6
        }
      },
      tooltip: {
        enabled: true,
        enabledOnSeries: [0]
      },
      grid: {
        show: false,
      },
      xaxis: {
        categories: data_cont["labels"],
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "gradient",
        colors: ["#0a72e8"],
        gradient: {
          shadeIntensity: 1,
          opacityFrom: [0.3,0],
          opacityTo: [0.7,0]
        }
      },
    };

    if(document.querySelector("#change_chart").innerHTML == ""){
      var chart = new ApexCharts(document.querySelector("#change_chart"), options);
      chart.render();
    }
  }

}

window.home_station_selector = function() {
  $("#station_station_id, #station_data_type").change(function(){
    $(this).parent("form").submit();
  })
}

$(document).ready( window.generate_norm_chart)
$(document).on('page:load', window.generate_norm_chart)
$(document).on('turbolinks:load', window.generate_norm_chart)

$(document).ready( window.generate_change_chart)
$(document).on('page:load', window.generate_change_chart)
$(document).on('turbolinks:load', window.generate_change_chart)

$(document).ready( window.home_station_selector)
$(document).on('page:load', window.home_station_selector)
$(document).on('turbolinks:load', window.home_station_selector)
